<template>

    <div class="accordion__item">
        <slot></slot>
    </div>

</template>


<script>

    export default {
        name: 'AccordionItem',
        data() {
            return {}
        },
        created() {},
        methods: {},
    }

</script>