<template>

    <div class="accordion__title"
         @click="toggle" :class="{'active': isActive}">
        <slot></slot>
    </div>

</template>


<script>

    export default {
        name: 'AccordionTitle',
        data() {
            return {
                multiple: this.$parent.$parent._props.multiple,
                isActive: false,
            }
        },
        created() {},
        methods: {
            toggle: function() {
                this.isActive = !this.isActive;
                this.$parent.$parent.items.forEach( item => {
                    item.$children[1].active = item.$children[0].isActive;
                });
            }
        },
    }

</script>