<template>

    <div>

        <Header></Header>

        <section class="intro intro_4" v-bind:style='{ backgroundImage: "url("+ intro.IntroBGUrl +")" }' id="intro">

            <div class="site__centered">

                <h1 class="intro__title" v-html="$t('documentsScreen.intro.title')"></h1><!-- /intro__title -->

            </div><!-- /site__centered -->

        </section><!-- /intro -->

        <section class="documents">

            <div class="site__centered site__centered_1280">

                <div class="nice-tabs">

                    <div class="nice-tabs__header">
                        <router-link :to="links.privacyPolicy" class="nice-tabs__header-item">{{$t('documentsScreen.privacyPolicy.title')}}</router-link>
                        <router-link :to="links.agreement" class="nice-tabs__header-item">{{$t('documentsScreen.agreement.title')}}</router-link>
                    </div><!-- /nice-tabs__header -->

                    <div class="nice-tabs__wrap">

                        <transition name="nice-tabs-fade" mode="out-in">

                            <router-view></router-view>

                        </transition>

                    </div><!-- /nice-tabs__wrap -->

                </div><!-- /nice-tabs -->

            </div><!-- /site__centered -->

        </section><!-- /documents -->

        <Footer></Footer>

    </div>

</template>

<script>
    import Header from "../components/TheHeader";
    import Footer from "../components/TheFooter";
    import IntroBGUrl from "@/assets/new/img/intro/intro__bg.jpg";

    export default {
        name: "DocumentsScreen",
        components: {
            Header,
            Footer,
        },
        data() {
            return {
                intro: {
                    IntroBGUrl,
                },
            }
        },
        computed: {
            links(){
                return this.$store.state.static.routePaths
            }
        },
        methods: {},
    }
</script>

<style lang="scss" scoped>

</style>
