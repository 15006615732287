<template>

    <div class="accordion">
        <slot></slot>
    </div><!-- /accordion -->

</template>


<script>

    export default {
        name: 'Accordion',
        props: {
            multiple: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                items: []
            }
        },
        mounted() {
            this.items = this.$children;
        },
        created() {},
        methods: {},
    }

</script>