<template>
    <div>
        <h2 class="nice-tabs__title">{{$t('documentsScreen.legalDocuments.title')}}</h2><!-- /nice-tabs__title -->
        <accordion>

            <accordion-item>
                <accordion-title>Отношения пользователя с Distribune</accordion-title>
                <accordion-inner>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt magni minima neque, non pariatur placeat porro tempore. Animi consequuntur deserunt enim id libero provident reiciendis tenetur. Architecto dolorum fuga impedit sint sit? At commodi doloremque est eveniet, expedita iusto laborum molestiae nam nesciunt qui? Aliquam minima possimus repudiandae sapiente veniam!
                </accordion-inner>
            </accordion-item>

            <accordion-item>
                <accordion-title>Принятие условий</accordion-title>
                <accordion-inner>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maiores, molestiae!</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur hic laboriosam maiores nobis optio ut.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab beatae consequuntur debitis, ea esse excepturi iste reiciendis soluta suscipit voluptas.</p>
                </accordion-inner>
            </accordion-item>

        </accordion>
    </div>
</template>

<script>
    import Accordion from '../accordion/Accordion';
    import AccordionItem from '../accordion/AccordionItem';
    import AccordionTitle from '../accordion/AccordionTitle';
    import AccordionInner from '../accordion/AccordionInner';

    export default {
        name: "legalDocumentsComponent",
        components: {
            Accordion,
            AccordionItem,
            AccordionTitle,
            AccordionInner,
        }
    }

</script>

<style scoped>

</style>
