<template>

    <transition name="accordion__inner"
                @enter="transitionStep1"
                @after-enter="transitionStep2"
                @before-leave="transitionStep3"
                @after-leave="transitionStep4">

        <div class="accordion__inner" v-if="active">
            <div class="accordion__inner-wrap">
                <div class="accordion__txt">
                    <slot></slot>
                </div>
            </div>
        </div>

    </transition>

</template>


<script>

    export default {
        name: 'AccordionInner',
        data() {
            return {
                active: false
            }
        },
        created() {},
        methods: {
            transitionStep1(el) {
                el.style.height = el.scrollHeight + 'px'
            },

            transitionStep2(el) {
                el.style.height = ''
            },

            transitionStep3(el) {
                el.style.height = el.scrollHeight + 'px'
            },

            transitionStep4(el) {
                el.style.height = ''
            },
        },
    }

</script>

<style lang="scss" scoped></style>